'use client';
import reducers from '@akinon/next/redux/reducers';
import {
  errorMiddleware,
  contextListMiddleware
} from '@akinon/next/redux/middlewares/checkout';
import {
  rtkQueryResponseHandler,
  rtkQueryErrorHandler
} from '@akinon/next/redux/middlewares';
import { api } from '@akinon/next/data/client/api';
import {
  Action,
  AnyAction,
  configureStore,
  Store,
  ThunkAction,
  ThunkDispatch
} from '@reduxjs/toolkit';
import categoryReducer from '@theme/redux/reducers/category';
import categoryMiddleware from '@theme/redux/middlewares/category';
import checkoutMiddleware from '@theme/redux/middlewares/checkout';
import preOrderMiddleware from '@theme/redux/middlewares/pre-order';
import quickCheckoutReducer from '@theme/redux/reducers/checkout';

const _reducers = {
  ...reducers,
  category: categoryReducer,
  quickCheckout: quickCheckoutReducer
};

const _middlewares = [
  api.middleware,
  rtkQueryResponseHandler,
  rtkQueryErrorHandler,
  errorMiddleware,
  preOrderMiddleware,
  contextListMiddleware,
  categoryMiddleware,
  checkoutMiddleware
];

export const makeStore = (): Store<{
  [key in keyof typeof _reducers]: ReturnType<(typeof _reducers)[key]>;
}> =>
  configureStore({
    reducer: _reducers,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware().concat([..._middlewares])
  });

export type AppStore = ReturnType<typeof makeStore>;

export type RootState = ReturnType<AppStore['getState']>;

export type TypedDispatch = ThunkDispatch<RootState, void, AnyAction>;

export type AppDispatch = AppStore['dispatch'];

export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

export const store = makeStore();

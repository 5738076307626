import clsx from 'clsx';
import { useAppSelector } from '@akinon/next/redux/hooks';
import { RootState } from '@theme/redux/store';
import { useSetPaymentOptionMutation } from '@akinon/next/data/client/checkout';
import { CheckoutPaymentOption } from '@akinon/next/types';
import { Icon, Radio } from '@theme/components';
import { usePaymentOptions } from '@akinon/next/hooks/use-payment-options';
import { Image } from '@akinon/next/components';

const isSafariOrIOSApplePayAvailable = () => {
  const isSafari = () => {
    return (
      typeof navigator !== 'undefined' &&
      /^((?!chrome|android).)*safari/i.test(navigator.userAgent)
    );
  };

  const isIOSDevice = () => {
    return /iPhone|iPad|iPod/.test(navigator.userAgent);
  };

  const isApplePayAvailable = () => {
    return (
      typeof window !== 'undefined' &&
      window.ApplePaySession &&
      window.ApplePaySession.canMakePayments()
    );
  };

  return isSafari() || (isIOSDevice() && isApplePayAvailable());
};

const PaymentOptionButtons = ({ children }) => {
  const { preOrder } = useAppSelector((state: RootState) => state.checkout);
  const [setPaymentOption] = useSetPaymentOptionMutation();
  const { filteredPaymentOptions } = usePaymentOptions();

  const onClickHandler = (option: CheckoutPaymentOption) => {
    setPaymentOption(option.pk);
  };

  const getIcon = (payment_type, slug) => {
    if (slug === 'apple-pay-wallet') {
      return '/akn-apple-pay.png';
    }

    switch (payment_type) {
      case 'credit-card':
        return '/akn-credit-card.png';
      case 'funds_transfer':
        return '/akn-cash.png';
      case 'tabby':
        return '/akn-tabby.png';
      case 'tamara':
        return '/akn-tamara.png';
      case 'gift-card':
        return '/akn-lulu-gift.png';
      case 'wallet':
        return '/akn-lulu-wallet.png';
      default:
        return '/akn-credit-card.png';
    }
  };

  return (
    <div>
      {filteredPaymentOptions.map((option) => (
        (option.slug !== 'apple-pay-wallet' || isSafariOrIOSApplePayAvailable()) && (
          <div
            key={`payment-option-${option?.pk}`}
            className={clsx(
              'flex flex-col gap-2.5 border-b border-gray-380 py-[0.875rem] last:border-none',
              option?.payment_type === 'loyalty_money' && 'hidden'
            )}
            data-testid={`checkout-payment-tab-${option?.pk}`}
          >
            <div
              className="flex items-center gap-2.5"
              onClick={() => onClickHandler(option)}
            >
              <Radio
                type="radio"
                name="payment-option"
                value={option.pk}
                checked={preOrder?.payment_option?.pk === option?.pk}
                className="h-5 w-5"
              />
              <div className="flex h-6 w-[2.1875rem] items-center justify-center rounded border border-gray-380 px-1.5 py-1">
                <Image
                  src={getIcon(option?.payment_type, option?.slug)}
                  width={24}
                  height={24}
                  alt=""
                />
              </div>
              <span className="cursor-pointer text-sm font-semibold text-black">
                {option?.name}
              </span>
            </div>
            {preOrder?.payment_option?.pk === option?.pk && children}
          </div>
        )
      ))}
    </div>
  );
};

export default PaymentOptionButtons;

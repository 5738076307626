import clsx from 'clsx';
import {
  useGetCheckoutLoyaltyBalanceQuery,
  usePayWithLoyaltyBalanceMutation
} from '@akinon/next/data/client/checkout';
import { useAppSelector } from '@akinon/next/redux/hooks';
import { useMemo } from 'react';
import { useLocalization } from '@akinon/next/hooks';
import { twMerge } from 'tailwind-merge';
import { Icon, Price } from '@theme/components';
import { Trans } from '@akinon/next/components';
import { LoaderLulu } from '@theme/components/loader-lulu';
import { getCookie } from '@akinon/next/utils';

export const StoreCredits = () => {
  const { t } = useLocalization();

  const [payWithLoyaltyBalance, { isLoading: isPayWithLoyaltyBalanceLoading }] =
    usePayWithLoyaltyBalanceMutation();

  const { loyaltyBalance, preOrder, steps } = useAppSelector(
    (state) => state.checkout
  );

  const { isLoading: isLoyaltyBalanceLoading } =
    useGetCheckoutLoyaltyBalanceQuery(undefined, {
      refetchOnMountOrArgChange: true,
      skip:
        !preOrder?.payment_option ||
        getCookie('isSelectedAddressChange') === 'true'
    });

  const isLoyaltyBalanceUsed = useMemo(() => {
    return parseFloat(preOrder?.loyalty_money ?? '0') > 0;
  }, [preOrder?.loyalty_money]);

  const handleClick = async () => {
    await payWithLoyaltyBalance(isLoyaltyBalanceUsed ? '0' : loyaltyBalance);
  };

  if (preOrder?.is_guest) {
    return null;
  }

  if (isLoyaltyBalanceLoading) {
    return (
      <div className="mb-3 px-4 py-3 xs:px-0">
        <LoaderLulu size={100} textClassName="text-sm" />
      </div>
    );
  }

  if (parseFloat(loyaltyBalance) <= 0) {
    return null;
  }

  return (
    <div
      className={twMerge(
        'px-4 xs:px-0',
        isPayWithLoyaltyBalanceLoading && 'pointer-events-none opacity-30'
      )}
    >
      <div className="mb-3 flex w-full items-center">
        <button onClick={handleClick}>
          <span
            className={clsx(
              'flex h-5 w-5 items-center justify-center rounded border border-solid border-primary',
              isLoyaltyBalanceUsed ? 'bg-primary' : 'bg-white'
            )}
          >
            <Icon
              name={isLoyaltyBalanceUsed ? 'check' : ''}
              size={10}
              className={clsx({ 'text-white': isLoyaltyBalanceUsed })}
            />
          </span>
        </button>

        <div className="pl-4">
          <p className="cursor-pointer text-sm" onClick={handleClick}>
            {t('checkout.payment.store_credit.use_my_store_credits')}
          </p>
          <p className="text-sm text-[#606060]">
            {t('checkout.payment.store_credit.available_balance')}:{' '}
            <strong>
              <Price
                value={loyaltyBalance}
                currencyCode={preOrder?.currency_type_label}
              />
            </strong>
          </p>
        </div>
      </div>

      {isLoyaltyBalanceUsed && parseFloat(preOrder?.unpaid_amount) > 0 && (
        <p className="my-4 text-[15px] font-medium text-[#707070] max-xs:text-xs">
          <Trans
            i18nKey="checkout.payment.store_credit.insufficient_balance"
            components={{
              Amount: (
                <Price
                  value={preOrder?.unpaid_amount}
                  currencyCode={preOrder?.currency_type_label}
                  className="text-[#ff0000]"
                />
              )
            }}
          />
        </p>
      )}
    </div>
  );
};

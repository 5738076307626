'use client';

import { useLocalization } from '@akinon/next/hooks';
import { LocaleUrlStrategy } from '@akinon/next/localization';
import { urlLocaleMatcherRegex } from '@akinon/next/utils';
import NextLink, { LinkProps as NextLinkProps } from 'next/link';
import { useMemo } from 'react';

type LinkProps = Omit<
  React.AnchorHTMLAttributes<HTMLAnchorElement>,
  keyof NextLinkProps
> &
  NextLinkProps & {
    href: string;
  };

export const Link = ({ children, href, ...rest }: LinkProps) => {
  const { locale, defaultLocaleValue, localeUrlStrategy } = useLocalization();

  const formattedHref = useMemo(() => {
    if (!href) {
      return '#';
    }

    if (typeof href === 'string' && !href.startsWith('http')) {
      const pathnameWithoutLocale = href.replace(urlLocaleMatcherRegex, '');
      const hrefWithLocale = `/${locale}${pathnameWithoutLocale}`;

      if (localeUrlStrategy === LocaleUrlStrategy.ShowAllLocales) {
        return hrefWithLocale;
      } else if (
        localeUrlStrategy === LocaleUrlStrategy.HideDefaultLocale &&
        locale !== defaultLocaleValue
      ) {
        return hrefWithLocale;
      }
    }

    return href;
  }, [href, defaultLocaleValue, locale, localeUrlStrategy]);

  const target = useMemo(() => {
    if (typeof window !== 'undefined' && window.self !== window.top) {
      return '_top';
    }
    return undefined;
  }, []);

  return (
    <NextLink href={formattedHref} target={target} {...rest}>
      {children}
    </NextLink>
  );
};

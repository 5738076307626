import SelectedPaymentOptionView from '@akinon/next/components/selected-payment-option-view';
import { useLocalization } from '@akinon/next/hooks';
import { CheckoutPaymentOption } from '@akinon/next/types';
import PaymentOptionButtons from './payment-option-buttons';
import { StoreCredits } from './options/store-credits';
import ApplePay from './options/apple-pay';

export const PaymentOptionViews: Array<CheckoutPaymentOption> = [
  {
    slug: 'apple-pay-wallet',
    view: ApplePay
  }
];

const PaymentStep = () => {
  const { t, locale } = useLocalization();

  return (
    <div className="w-full rounded-[0.875rem] border border-gray-380 pt-1.5">
      <h3 className="w-full border-b border-gray-380 px-5 py-2.5 text-sm font-bold text-black-500">
        {t('checkout.payment.title')}
      </h3>
      <div className="w-full px-5 py-4">
        <StoreCredits />
        <PaymentOptionButtons>
          <SelectedPaymentOptionView />
        </PaymentOptionButtons>
      </div>
    </div>
  );
};

export default PaymentStep;

import clsx from 'clsx';
import { forwardRef, useState, Ref } from 'react';
import { Controller } from 'react-hook-form';
import { PatternFormat, PatternFormatProps } from 'react-number-format';
import { InputProps } from '@theme/components/types';
import { twMerge } from 'tailwind-merge';
import { Icon } from '@theme/components';

type CustomPropsType = {
  forCVC?: boolean;
};
const PatternFormatWithRef = forwardRef(
  (props: PatternFormatProps, ref: Ref<HTMLInputElement>) => {
    return <PatternFormat {...props} getInputRef={ref} />;
  }
);
PatternFormatWithRef.displayName = 'PatternFormatWithRef';

export const Input = forwardRef<
  HTMLInputElement,
  CustomPropsType &
    InputProps &
    Pick<
      PatternFormatProps,
      'mask' | 'allowEmptyFormatting' | 'onValueChange'
    > & {
      format?: string;
      defaultValue?: string;
      type?: string;
    }
>((props, ref) => {
  const {
    id,
    label,
    error,
    mask,
    format,
    required = false,
    type,
    containerClassName,
    forCVC = false,
    ...rest
  } = props;

  const [showPassword, setShowPassword] = useState(false);

  const inputClass = twMerge(
    clsx(
      'relative h-[58px] w-full rounded-lg border-[0.5px] py-[18px] pe-[50px] ps-5 text-base leading-4 tracking-[-0.03em]',
      'focus-visible:outline-none', // disable outline on focus
      error
        ? 'border-error-650 text-error-650 focus:border-error-650'
        : 'border-gray-480 text-black hover:border-black focus:border-black'
    ),
    props.className
  );

  const inputProps: any = {
    id,
    ref,
    type: showPassword ? 'text' : type,
    className: inputClass
  };

  const Label = () => {
    if (!label) return null;

    return (
      <label
        htmlFor={id}
        className={clsx(
          'pointer-events-none absolute left-4 z-10 -translate-y-1/2 bg-white px-[3px] text-sm leading-5 text-gray-980'
        )}
      >
        {label}
      </label>
    );
  };

  const Icons = () => {
    if (error && type !== 'password') {
      return (
        <Icon
          name="input-error-circle"
          size={24}
          className="absolute-center-y right-5 z-10 hidden cursor-pointer text-error-650"
        />
      );
    }

    if (type === 'password') {
      return (
        <Icon
          name={showPassword ? 'eye-on' : 'eye-off'}
          size={24}
          onMouseDown={(e) => {
            e.preventDefault();
            setShowPassword(!showPassword);
          }}
          className={twMerge(
            clsx(
              'absolute-center-y end-5 z-10 cursor-pointer',
              'focus-visible:outline-none',
              error ? 'text-error-650' : 'text-gray-560'
            )
          )}
        />
      );
    }

    return null;
  };

  return (
    <div className={clsx('flex w-full flex-col', props.containerClassName)}>
      <div className="relative flex flex-col">
        {props.format ? (
          <>
            <Label />
            <Controller
              name={props.name ?? ''}
              control={props.control}
              render={({ field }) => (
                <PatternFormatWithRef
                  format={format}
                  mask={mask ?? ''}
                  {...rest}
                  {...field}
                  {...inputProps}
                  type={type as 'text' | 'password' | 'tel'}
                />
              )}
            />
            {!forCVC && <Icons />}
          </>
        ) : (
          <>
            <Label />
            <input {...rest} {...inputProps} />
            <Icons />
          </>
        )}
      </div>

      {error && (
        <span className="mt-3 text-xs text-error-650">{error.message}</span>
      )}
    </div>
  );
});

Input.displayName = 'Input';

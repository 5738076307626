export * from '@theme/types/widgets';

export type RegisterFormType = {
  gender: string;
  phone: string;
  email: string;
  password: string;
  first_name: string;
  last_name: string;
  confirm: boolean;
  email_allowed: boolean;
  sms_allowed: boolean;
  formType: string;
  locale: string;
  country_code?: string;
  detail?: string;
  happiness_program: boolean;
};

export type LoginFormType = {
  email?: string;
  password?: string;
  phone?: string;
  code?: string;
  formType: string;
  locale: string;
  remember?: boolean;
};

export type OtpModalFormType = {
  phone: string;
  code?: string;
  locale: string;
  formType: string;
};

export enum WIDGET_TYPE {
  category = 'category',
  multiselect = 'multiselect',
  range = 'range'
}

export interface CreditCardForm {
  card_holder: string;
  card_number: string;
  card_month: string;
  card_year: string;
  card_cvv: string;
  installment: number;
  agreement: boolean;
  save: boolean;
}

export interface PriceProps {
  currencyCode?: string;
  useCurrencySymbol?: boolean;
  useCurrencyAfterPrice?: boolean;
  useCurrencySpace?: boolean;
  useNegative?: boolean;
  useNegativeSpace?: boolean;
}

export interface SeoProps {
  title?: string;
  description?: string;
  keywords?: string;
  itemPropName?: string;
  itemPropDescription?: string;
  itemPropImage?: string;
  twitterCard?: string;
  twitterTitle?: string;
  twitterDescription?: string;
  twitterData1?: string;
  twitterLabel1?: string;
  ogTitle?: string;
  ogImage?: string;
  ogDescription?: string;
  ogPriceAmount?: string;
  ogPriceCurrency?: string;
}

export interface ProductVariantItem {
  date?: string | null;
  time?: string | string[];
  variant?: object | null;
  message?: string | null;
  slug?: string | null;
}

export interface OrderDetailRateForm {
  title: string;
  review: string;
}

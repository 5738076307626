'use client';

import { checkoutApi } from '@akinon/next/data/client/checkout';
import { useAppDispatch, useAppSelector } from '@akinon/next/redux/hooks';
import { getCookie } from '@akinon/next/utils';
import { getUrlPathWithLocale } from '@akinon/next/utils/localization';
import { RootState } from '@theme/redux/store';
import { useMemo, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';

export default function ApplePay() {
  const { handleSubmit } = useForm();
  const { walletPaymentData, preOrder } = useAppSelector(
    (state: RootState) => state.checkout
  );
  const dispatch = useAppDispatch();
  const [errors, setErrors] = useState(null);

  const paymentErrors = useMemo(() => {
    if (typeof errors === 'string') {
      return errors;
    } else if (Array.isArray(errors)) {
      return errors.join(', ');
    } else if (typeof errors === 'object') {
      return Object.values(errors ?? {}).join(', ');
    }

    return null;
  }, [errors]);

  const onMerchantValidation = async (event: any) => {
    const walletSelectionPageResponse = await dispatch(
      checkoutApi.endpoints.setWalletSelectionPage.initiate({
        payment_option: preOrder.payment_option?.pk,
        validationURL: event.validationURL
      })
    ).unwrap();

    const walletPaymentPageContext =
      walletSelectionPageResponse.context_list.find(
        (c) => c.page_name === 'WalletPaymentPage'
      );

    if (!walletPaymentPageContext) {
      return;
    }

    event.complete(walletPaymentPageContext.page_context.context_data);
  };

  const onSubmit: SubmitHandler<null> = async () => {
    const getPlaceOrderButtons = document.querySelectorAll(
      '#summary-place-order-button'
    );

    getPlaceOrderButtons?.forEach((getPlaceOrderButton) => {
      getPlaceOrderButton?.setAttribute('disabled', 'disabled');
    });

    setErrors(null);

    try {
      const paymentMethodData = [
        {
          supportedMethods: walletPaymentData.supportedMethods,
          data: walletPaymentData.data
        }
      ];

      const paymentDetails = {
        total: {
          label: walletPaymentData.detail.label,
          amount: walletPaymentData.detail.amount
        },
        displayItems: [
          {
            ...walletPaymentData.detail
          }
        ]
      };

      const request = new PaymentRequest(
        paymentMethodData,
        paymentDetails
      ) as any;

      request.onmerchantvalidation = onMerchantValidation;

      const paymentRequestResponse = await request.show();

      if (paymentRequestResponse.details?.token?.paymentData) {
        const paymentData = paymentRequestResponse.details.token.paymentData;

        const walletPaymentPageResponse = await dispatch(
          checkoutApi.endpoints.setWalletPaymentPage.initiate({
            payment_token: JSON.stringify(paymentData)
          })
        ).unwrap();

        if (walletPaymentPageResponse.errors) {
          setErrors(walletPaymentPageResponse.errors);
        }

        if (
          walletPaymentPageResponse.context_list.find(
            (c) => c.page_name === 'WalletCompletePage'
          )
        ) {
          const paymentCompleteResponse = await dispatch(
            checkoutApi.endpoints.setWalletCompletePage.initiate()
          ).unwrap();

          if (paymentCompleteResponse.errors) {
            setErrors(paymentCompleteResponse.errors);
          }

          if (
            paymentCompleteResponse.context_list.find(
              (c) => c.page_name === 'ThankYouPage'
            )
          ) {
            const redirectUrl = paymentCompleteResponse.context_list.find(
              (c) => c.page_name === 'ThankYouPage'
            )?.page_context.context_data.redirect_url;

            const redirectUrlWithLocale = `${
              window.location.origin
            }${getUrlPathWithLocale(redirectUrl, getCookie('pz-locale'))}`;

            await paymentRequestResponse.complete('success');
            window.location.href = redirectUrlWithLocale;
          } else {
            await paymentRequestResponse.complete('fail');
          }
        } else {
          await paymentRequestResponse.complete('fail');
        }
      }
    } catch (error) {
      getPlaceOrderButtons?.forEach((getPlaceOrderButton) => {
        getPlaceOrderButton?.removeAttribute('disabled');
      });
    }
  };

  if (!walletPaymentData) {
    return null;
  }

  return (
    <form
      id="paymentForm"
      onSubmit={handleSubmit(onSubmit)}
      className="flex items-center gap-2.5 px-[1.875rem] py-2"
    >
      {paymentErrors?.length > 0 && (
        <div className="text-red-500 text-center text-sm">{paymentErrors}</div>
      )}
    </form>
  );
}
